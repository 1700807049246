<template>
  <div class="cashbacks container">
    <loading v-if="loading"/>
    <template v-else>
      <b-row align-h="center" class="front">
        <b-col cols="6" sm="3" v-for="item in frontCashbacks" :key="item.id">
          <div class="card front-card" @click="viewItem(item)">
            <div class="card-image">
              <square :src="item.picList[0] + '!640'" alt="用户晒单" height="66.7%"/>
            </div>
            <div class="card-body text-left">
              <p class="card-text" :title="item.content">{{item.content}}</p>
              <div class="nickname text-right">
                {{item.creator.name || '匿名用户'}}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="center" class="back" v-if="backCashbacks.length">
        <b-col sm="3" v-for="item in backCashbacks" :key="item.id">
          <div class="card back-card" @click="viewItem(item)">
            <div class="card-image">
              <square :src="item.picList[0] + '!640'" alt="用户晒单" height="66.7%"/>
            </div>
            <div class="card-body">
              <p class="card-text" :title="item.content">{{item.content}}</p>
              <div class="nickname text-right">{{item.creator.name || '匿名用户'}}</div>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="btn-area mt-3">
        <b-btn block to="/unboxing">查看更多晒单</b-btn>
      </div>
    </template>

    <b-modal title="晒单详情" id="cashback" hide-footer>
      <template v-if="active">
        <header class="mb-3">
          <b-row>
            <b-col cols="auto" class="pr-0">
              <avatar :src="active.creator.avatar" size="3em"/>
            </b-col>
            <b-col>
              <div class="user-info">
                <span class="time text-muted float-right">
                  发布于
                  <datetime :value="active.createAt"/>
                </span>
                <b class="nickname">{{active.creator.name}}</b>
                <div v-if="active.book">
                  <b-badge>{{active.bookType | productName(true)}}</b-badge>
                  <b-badge variant="primary">{{active.book.type | bookType}}</b-badge>
                  <b-badge variant="success">{{active.book.bindingName}}</b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
        </header>
        <div class="item-detail">
          <p class="text">{{active.content.trim()}}</p>
          <div class="images" v-if="active.picList">
            <square class="mt-3" alt :src="image" img-size="800" height="auto"
                    v-for="image in active.picList" :key="image"/>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'theirWords',
  props: ['filter'],
  data() {
    return {
      active: null,
      loading: true,
      cashbackList: []
    }
  },
  created() {
    this.fetchList().then(data => {
      this.cashbackList = data
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  },
  methods: {
    fetchList() {
      return this.$req.get('/api/helpdesk/request/get_all_cashback_rank?size=7')
    },
    viewItem(item) {
      this.active = item
      this.$bvModal.show('cashback')
    }
  },
  computed: {
    frontCashbacks() {
      return this.cashbackList.slice(0, 4)
    },
    backCashbacks() {
      return this.cashbackList.slice(4, 7)
    }
  }
}
</script>
<style lang="scss" scoped>
.cashbacks {
  position: relative;

  .loading {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .back {
    opacity: .6;
    margin-top: -10rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .front {
    position: relative;
    z-index: 1;
  }

  .card-text {
    @include line-clamp(3);
    height: 60px;
  }

  .card {
    border: none;
    border-radius: 12px;
    box-shadow: $box-shadow;
    transition: .3s;
    cursor: pointer;
  }
}

.item-detail {
  .text {
    white-space: pre-wrap;
  }

  .images {
    text-align: center;
  }

  img {
    margin: .5rem 0;
    max-width: 100%;
    width: 480px;
    border-radius: 4px;
    border: 1rem solid #eee;
  }
}
</style>
