<template>
  <div class="home">
    <!-- banner -->
    <section class="banners p-0">
      <swiper :options="bannerOptions" v-if="banners.length">
        <swiper-slide :key="item.pic" v-for="item in banners">
          <div class="topbar" v-if="item.dark"></div>
          <square :height="isSmallScreen ? '56.25%' : '37.5%'" :src="item.pic"
                  v-on="getEvents(item)"/>
          <div class="container" v-if="item.title || item.button">
            <div class="texts">
              <h1 class="text-primary" v-if="item.title" v-image-text>{{item.title}}</h1>
              <div class="caption" v-if="item.subtitle" v-image-text>{{item.subtitle}}</div>
              <div class="mt-3" v-if="item.button && item.link">
                <b-btn :to="item.link" class="px-sm-5 py-sm-2" variant="primary"
                       @click="$gtag.event('click_banner_button', {event_label: item.link})">
                  {{item.button}}
                </b-btn>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      </swiper>
      <square v-else disabled :height="isSmallScreen ? '53%' : '35%'"/>
    </section>

    <!-- 产品 -->
    <section class="products">
      <header>
        <h1 v-image-text>— 我们的产品 —</h1>
        <div class="caption" v-image-text>Our Products</div>
      </header>
      <div class="container text-sm-center">
        <b-row align-h="center">
          <b-col :key="item.title" cols="12" md="4" sm="6" v-for="item in products">
            <b-card class="flex-row flex-sm-column align-items-center" no-body>
              <b-link :to="'/products/' + item.tid" class="w-100" tag="div">
                <square :src="item.hero" height="75%" radius="0"/>
              </b-link>
              <b-card-body class="py-sm-4" style="min-width: 12em;">
                <h4>
                  {{item.title}}
                  <b-badge v-if="item.badge" variant="primary">{{item.badge}}</b-badge>
                </h4>
                <div>{{item.shortDesc}}</div>
                <div class="mt-2">
                  <b-link :to="'/products/' + item.tid">
                    了解详情
                    <fa icon="chevron-right"/>
                  </b-link>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </section>

    <!-- 使用场景 -->
    <section class="people">
      <header>
        <h1 v-image-text>— 人人都是生活的作者 —</h1>
        <div class="caption" v-image-text>Everyone is author of life</div>
      </header>
      <div class="container">
        <div class="section" v-for="(item, index) in people" :key="item.title">
          <b-row :class="{'flex-row-reverse': index % 2}" align-v="center" class="mx-0">
            <b-col class="px-0" sm>
              <square :src="item.pics[0]" height="60%"></square>
            </b-col>
            <b-col class="px-0" sm="4">
              <div class="info">
                <div :style="{'background-color': item.color}" class="section-title">
                  {{item.title}}
                </div>
                <div :style="{color: item.color}" class="desc">{{item.desc}}</div>
                <div class="small mt-2">
                  <b-link @click="$previewImage({url: item.pics[0], urls: item.pics})">查看更多</b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>

    <!-- 用户说 -->
    <section class="their-words">
      <header>
        <h1 v-image-text>— 他们说 —</h1>
        <div class="caption" v-image-text>Their words</div>
        <div class="slogan" v-image-text>「这是一份比任何东西都让人期待和惊喜的礼物！」</div>
      </header>
      <their-words/>
      <div class="btn-area">
        <b-btn block to="/unboxing">查看更多评价</b-btn>
      </div>
    </section>

    <!-- 关于我们 -->
    <section class="about-us">
      <header>
        <h1 v-image-text>— 关于心书·微信书 —</h1>
        <div class="caption" v-image-text>About us</div>
      </header>
      <div class="container text-center">
        <div class="text">
          <p>2014年8月，第一本朋友圈出版的书
            <sup>[
              <a href="https://jingyan.baidu.com/article/4dc408489a67b6c8d846f178.html"
                 target="_blank">百度经验
              </a>
              ]</sup>
            面世，我们给他一个名字［微信书］，
          </p>
          <p>它普通到几乎不像一本书，却带给每个普通人都能出书的希望。</p>
          <p>在淡漠书写的今天，时光越来越难以抓住，</p>
          <p>幸好我们用微信、微博记录生活，幸好心书将故事一键成书，永久珍藏！</p>
          <p>因为
            <span class="text-primary">{{latestUserNumber}}</span>
            名用户选择心书，
          </p>
          <p>我们相信，你的故事也值得出版。</p>
        </div>
        <div class="btn-area">
          <b-btn block to="/books" variant="primary">{{hasLogin ? '进入书架' : '开始制作'}}</b-btn>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { chunk } from 'lodash'

export default {
  name: 'home',
  mixins: [
    require('@/mixins/preview-image').default,
    require('@/mixins/swiper').default
  ],
  components: {
    TheirWords: require('@/components/TheirWords').default
  },
  title: '心书·微信书 - 朋友圈回忆，一键成书 | 照片书 | 订制书 | 心书网',
  data() {
    return {
      banners: [],

      products: [
        {
          tid: 'wxbook',
          title: '微信书',
          shortDesc: '微信从此跃然纸上',
          hero: 'https://img.xinshu.me/upload/376dda4fc096410d8d9ef455f170df12!480'
        }, {
          tid: 'wbbook',
          title: '微博书',
          shortDesc: '微博时光，一键成书',
          hero: 'https://img.xinshu.me/upload/b7c5585f6f4b4bf0bcaeec4ccb897d38!480'
        }, {
          tid: 'diary',
          title: '日记书',
          shortDesc: '用照片记录生活',
          hero: 'https://img.xinshu.me/upload/f883daaaa23b46979cd42f2593045644!480'
        }, {
          tid: 'blogbook',
          title: '长文集',
          shortDesc: '记录心情，打印生活的美',
          hero: require('../assets/images/detail/blogbook/swiper-2.jpg')
        }
      ],

      people: [
        {
          title: '妈妈们',
          desc: '随手发在朋友圈的宝宝日常照片和趣事，简单导出就是一本成长日记。',
          color: '#C89666',
          pics: [
            require('../assets/images/home/story/1-1.jpg'),
            require('../assets/images/home/story/1-2.jpg'),
            require('../assets/images/home/story/1-3.jpg')
          ]
        }, {
          title: '情侣们',
          desc: '没有什么比你们发在朋友圈里共同的回忆，更值得用书这种介质珍藏起来了。',
          color: '#E4797D',
          pics: [
            require('../assets/images/home/story/2-1.jpg'),
            require('../assets/images/home/story/2-2.jpg'),
            require('../assets/images/home/story/2-3.jpg')
          ]
        }, {
          title: '旅行家',
          desc: '你在各地旅行时发在朋友圈的美景见闻，就是你的旅行日记。',
          color: '#508396',
          pics: [
            require('../assets/images/home/story/3-1.jpg'),
            require('../assets/images/home/story/3-2.jpg'),
            require('../assets/images/home/story/3-3.jpg')
          ]
        }, {
          title: '生活家',
          desc: '每个热爱生活的人都该有属于自己的生活手册，微信书就是这本手册。',
          color: '#709668',
          pics: [
            require('../assets/images/home/story/4-1.jpg'),
            require('../assets/images/home/story/4-2.jpg'),
            require('../assets/images/home/story/4-3.jpg')
          ]
        }
      ],
      bannerOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000
        }
      },
      bookCoverLayouts: '',
      cashbackList: [],
      latestUserNumber: 4154110,
      layoutIndex: 2
    }
  },
  methods: {
    chunk,
    getEvents(item) {
      if (item.url) {
        return {
          click: () => {
            this.$gtag.event('click_banner', {
              event_label: item.url
            })
            if (item.url.startsWith('https://weixinshu.com')) {
              item.url = item.url.replace('https://weixinshu.com', '')
            }
            if (item.url.startsWith('/')) {
              return this.$router.push(item.url)
            }
            location.href = item.url
          }
        }
      }
      return null
    }
  },
  created() {
    this.$ajax.fetchHomeConfig().then(data => {
      this.banners = data.banners.filter(i => {
        if (!i.limited) {
          return true
        }
        return this.$day().isBetween(i.start, i.end)
      })
    })

    this.$ajax.fetchUserCount().then(data => {
      this.latestUserNumber = data
    })

    this.$ajax.fetchAlbumProducts().then(results => {
      results = results.filter(i => !i.disabled).filter(i => i.tid !== 'xsalbum')
      this.products = this.products.concat(results)
    })
  },
  mounted() {
    this.clientWidth = `${document.documentElement.clientWidth}px`
    window.onresize = function temp() {
      this.clientWidth = `${document.documentElement.clientWidth}px`
    }
  }
}
</script>

<style lang="scss">
body[data-page="home"] {
  .site-nav {
    background-color: transparent;
    border-bottom: 0;

    .navbar-nav {
      padding-left: 1em;

      .nav-link {
        color: #555;
      }
    }

    .btn {
      text-shadow: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.home.container {
  padding: 0;
  width: 100%;
  max-width: 100%;

  ::v-deep .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: $primary;
  }

  .btn-area {
    max-width: 25em;
  }

  .topbar {
    position: absolute;
    background-color: #fff;
    opacity: .8;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  > section {
    position: relative;
    padding: 3rem 1rem;

    > header {
      margin-bottom: 2rem;
      text-align: center;

      .slogan {
        margin-top: 1em;
      }

      .title {
        margin-bottom: .5rem;
      }

      .sub-title {
        font-size: 1.2em;
      }
    }

    > .container {
      padding: 0;

      .name {
        color: $body-color;
      }
    }

    &.banners {
      .container {
        width: 90%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      h1 {
        color: $primary;
        white-space: nowrap;
        width: 200vw;
      }

      .texts {
        opacity: 0;
      }

      .swiper-slide-active {
        .texts {
          transition: opacity 1s;
          opacity: 1;
        }
      }
    }

    &.products {
      padding-bottom: 4rem;

      @include media-breakpoint-up(sm) {
        .container {
          position: relative;

          &:before, &:after {
            display: block;
            content: '';
            width: 25%;
            height: 100%;
            background-image: url(../assets/images/home/product/bg.png);
            background-repeat: no-repeat;
            background-position: top left;
            position: absolute;
            background-size: contain;
            top: -5%;
            left: -5%;
            z-index: -1;
          }

          &:after {
            top: auto;
            left: auto;
            right: -5%;
            bottom: -5%;
            background-position: bottom right;
            opacity: .8;
          }
        }
      }

      .card {
        border: 0;
        box-shadow: 0 12px 40px rgba(175, 175, 175, 0.2);
        border-radius: 8px;
        overflow: hidden;
      }
    }

    &.people {
      .section + .section {
        margin-top: 2rem;
      }

      .section {
        text-align: left;
        background-color: #f7f7f7;
        border-radius: 4px;
        box-shadow: $box-shadow;
        font-size: 1.25em;
      }

      .section-title {
        display: inline-block;
        color: #fff;
        margin-bottom: .5em;
        padding: .25rem 1rem;
        border-radius: 100px;
      }

      .info {
        padding: 2rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    > section {
      padding: 2rem 1rem;

      &.banner .footer {
        position: relative;
        padding: 1em;
      }
    }
  }
  @include media-breakpoint-up(md) {
    margin-top: -56px;
  }
}
</style>
