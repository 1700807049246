<template>
  <transition name="fade">
    <div class="image-preview" v-if="active">
      <swiper :options="options" ref="imageSwiper">
        <swiper-slide v-for="(image, index) in images" :key="index">
          <img draggable="false" :data-src="image" class="swiper-lazy" @click.stop="close"
               :style="{transform: 'rotate(' + rotate[index] + 'deg)'}" alt="">
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </swiper-slide>
        <template v-if="showAction">
          <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        </template>
      </swiper>
      <div class="toolbar" hidden>
        <b-btn variant="outline-black" size="lg" @click="rotateNext">
          <fa icon="redo"/>
        </b-btn>
      </div>
      <div class="close" @click.stop="close">
        <fa icon="times"/>
      </div>
    </div>
  </transition>
</template>

<script>
import { isString } from 'lodash'

import { mapGetters } from 'vuex'
import { previewImage as wxPreviewImage } from '@/modules/wechat'

export default {
  name: 'imagePreviewer',
  mixins: [require('@/mixins/swiper').default],
  data() {
    return {
      options: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        lazy: true,
        keyboard: true
      },
      active: false,
      rotate: [],
      images: []
    }
  },
  beforeDestroy() {
    this.active = false
    document.body.classList.remove('modal-open')
  },
  computed: {
    ...mapGetters(['previewing']),
    showAction() {
      return this.images.length > 1
    }
  },
  methods: {
    close() {
      this.active = false
      document.body.classList.remove('modal-open')
    },
    show(payload) {
      this.setPreview(payload)
      this.active = true
      document.body.classList.add('modal-open')
    },
    rotateNext() {
      const index = this.$refs.imageSwiper.swiper.activeIndex
      this.rotate[index] = this.rotate[index] || 0
      this.rotate[index] += 90
      if (this.rotate[index] >= 360) {
        this.rotate[index] = 0
      }
      this.rotate = [...this.rotate]
    },
    setPreview(data) {
      if (isString(data)) {
        data = {url: data}
      }

      if (data && data.url) {
        let {urls, url} = data

        url = url || ''
        urls = urls || [url]

        if (window.isWechat) {
          return wxPreviewImage(url, urls)
        }

        let index = urls.indexOf(url)
        index = index > -1 ? index : 0

        this.images = urls.map(url => this.$img(url, 1920))
        this.options.initialSlide = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .swiper-container {
    height: 100%;
  }

  .toolbar {
    position: fixed;
    padding: .5rem;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, .3);
    right: 2rem;
    bottom: 2rem;
    margin: auto;
    max-width: 6rem;
    z-index: 1;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 100px;
    padding: .5em .5em 1em 1em;
    color: #fff;
    background-color: $text-muted;
    z-index: 20;

    i {
      line-height: 1;
    }
  }

  .prev, .next {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    color: #ccc;
    font-size: 2em;
    width: 1em;
    line-height: 3;
    height: 3em;
    cursor: pointer;
    z-index: 20;

    &:hover {
      color: #fff;
    }
  }

  $image-size: 768px;
  .prev {
    left: 50%;
    margin-left: -$image-size / 2 + 32px;
  }

  .next {
    right: 50%;
    margin-right: -$image-size / 2 + 32px;
  }

  .image-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    z-index: 100;
    user-select: none;

    &.active {
      display: block;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: zoom-out;
    }
  }

  .swipe-indicator {
    background-color: #666;
    opacity: 1;

    &.active {
      background-color: #fff;
    }
  }

  ::v-deep .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background: #999;
  }

  .swipe-item {
    &.loading {
      background-image: url(../assets/images/loading.gif);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
</style>
