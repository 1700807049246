/**
 * Created by chenzhuokai on 2016/10/13.
 */
/* global wx, WeixinJSBridge */
import loadJS from 'load-script-once'
import { assign, isObject } from 'lodash'

import request from '@/plugins/request'
import { wxReady } from '@/utils/wait-for'

let landingUrl = ''
landingUrl = location.href
console.log('Landing Url', landingUrl)

let useLandingUrl = false

if (window.isWechat) {
  getSdk()
}

export function wxPay(payArgs) {
  if (!window.isWechat) {
    return Promise.reject(new Error('Can not invoke wxpay outside wechat'))
  }
  return new Promise((resolve, reject) => {
    function onBridgeReady() {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: payArgs.appId,
          timeStamp: payArgs.timeStamp,
          nonceStr: payArgs.nonceStr,
          package: 'prepay_id=' + payArgs.prepayId,
          signType: 'MD5',
          paySign: payArgs.paySign
        },
        res => {
          const errMsg = res.err_msg || res.errMsg
          if (/ok/i.test(errMsg)) {
            return resolve()
          }
          if (/cancel/i.test(errMsg)) {
            return reject(new Error('cancelled'))
          }
          reject(new Error(errMsg))
        }
      )
    }

    if (typeof WeixinJSBridge === 'undefined') {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
    } else {
      onBridgeReady()
    }
  })
}

export function chooseImage(count = 9, sizeType = ['original'], sourceType = ['album']) {
  if (!window.isWechat) {
    return
  }
  return wxReady().then(config).then(() => {
    return new Promise((resolve, reject) => {
      wx.chooseImage({
        count,
        sizeType,
        sourceType,
        success(res) {
          resolve(res.localIds)
        },
        fail() {
          reject(new Error('wx.chooseImage failed'))
        }
      })
    })
  })
}

export function uploadImage(localId, showProgress) {
  if (!window.isWechat) {
    return
  }
  return wxReady().then(config).then(() => {
    return new Promise((resolve, reject) => {
      wx.uploadImage({
        localId,
        isShowProgressTips: showProgress ? 1 : 0,
        success(res) {
          resolve(res.serverId)
        },
        fail() {
          reject(new Error('wx.uploadImage failed'))
        }
      })
    })
  })
}

export function getImageData(localId) {
  if (!window.isWechat) {
    return
  }
  return wxReady().then(() => {
    return new Promise((resolve, reject) => {
      wx.getLocalImgData({
        localId, // 图片的localID
        success(res) {
          resolve(res.localData)
        },
        fail() {
          reject(new Error('wx.getLocalImgData failed'))
        }
      })
    })
  })
}

export function downloadImage(serverId, showProgress = true) {
  if (!window.isWechat) {
    return
  }
  return wxReady().then(() => {
    return new Promise((resolve, reject) => {
      wx.downloadImage({
        serverId,
        isShowProgressTips: showProgress ? 1 : 0,
        success: resolve,
        fail() {
          reject(new Error('wx.downloadImage failed'))
        }
      })
    })
  })
}

let configShareTimeout = null

/**
 * @description
 * 配置微信分享文案, 调用后会直接使用微信 JSSDK 提供的接口进行分享配置
 *
 * @param {Object} shareConfig - 微信分享的文案
 * @param {String} shareConfig.title - 分享标题
 * @param {String} shareConfig.desc - 分享描述
 * @param {String} shareConfig.link - 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
 * @param {String} shareConfig.imgUrl - 分享图标
 * @param {String} shareConfig.success - 成功回调
 * @param {String} shareConfig.cancel - 取消回调
 */
export function configShare(shareConfig) {
  if (!window.isWechat) {
    return
  }
  clearTimeout(configShareTimeout)
  const {title, link} = shareConfig
  if (!link && title) {
    console.warn('Share method should always have title and link parameters.')
  }
  configShareTimeout = setTimeout(() => {
    _configShare(shareConfig)
  }, 500)
}

function _configShare(shareConfig) {
  // 配置分享信息
  if (!isObject(shareConfig)) {
    return Promise.reject(new Error('Config invalid'))
  }

  return wxReady().then(config).then(() => {
    wx.updateAppMessageShareData({
      ...shareConfig
    })

    wx.updateTimelineShareData({
      ...shareConfig,
      title: shareConfig.timelineTitle || shareConfig.title
    })
  })
}

/**
 * 调用微信预览图片接口
 * @param current {String} 当前显示图片
 * @param urls {Array} 图片列表
 */
export function previewImage(current, urls = []) {
  current = current.replace(/^http:/, 'https:').split('!')[0]
  urls = urls.map(url => url.replace(/^http:/, 'https:').split('!')[0])

  wxReady().then(() => {
    wx.previewImage({
      current, // 当前显示图片的http链接
      urls // 需要预览的图片http链接列表
    })
  })
}

export function config(retryTimes = 1) {
  if (window.wx && wx.configReady === location.href) {
    return Promise.resolve()
  }
  return getSign(useLandingUrl).then(data => {
    return new Promise((resolve, reject) => {
      if (window.DEBUG) {
        wx.configReady = location.href
        return resolve()
      }
      wx.error(err => {
        console.error('Config error', err)
        wx.configError = true
        wx.configReady = ''
        console.log('Config error, trying for', retryTimes, 'time')
        retryTimes++
        if (retryTimes < 3) {
          useLandingUrl = !useLandingUrl
          return resolve(config(retryTimes))
        }
        reject(err)
      })

      wx.ready(() => {
        // ready 无论配置是否失败都会触发
        // 所以让 resolve 延迟触发，如果 error 已经触发，timeout 就不会执行
        if (wx.configError) {
          return
        }

        console.log('Config ready')
        wx.configReady = location.href
        wx.configError = false
        clearTimeout(wx.timeout)
        retryTimes = 1
        useLandingUrl = false
        resolve()
      })
      console.log('Config start', useLandingUrl)
      wx.configError = false
      wx.config(assign(data, {
        appId: data.appid || data.appId,
        debug: window.DEBUG,
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr || data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        openTagList: [
          'wx-open-launch-app',
          'wx-open-launch-weapp'
        ],
        jsApiList: [
          'showMenuItems',
          'hideMenuItems',
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'previewImage',
          'chooseWXPay',
          'chooseImage',
          'uploadImage',
          'downloadImage',
          'miniProgram'
        ]
      }))
    })
  })
}

function getSign(useLandingUrl) {
  let url = useLandingUrl ? landingUrl : window.location.href
  url = url.split('#')[0]
  const data = {
    url: url,
    fromAccount: 'gh_1fd5050837d5'
  }
  return request.post('/api/get_sign', data)
}

async function getSdk() {
  try {
    await loadJS('https://res.wx.qq.com/open/js/jweixin-1.6.0.js')
  } catch (err) {
    await loadJS('https://res2.wx.qq.com/open/js/jweixin-1.6.0.js')
  }
  console.log('Weixin JSSDK Loaded')
}
