/**
 * Created by chenzhuokai on 2017/2/8.
 */
import Vue from 'vue'
import ImagePreviewer from '@/components/ImagePreviewer.vue'
import { proxyUrl } from '@/utils/utils'
import { previewImage } from '@/modules/wechat'

export default {
  methods: {
    $previewImage(payload) {
      if (typeof payload === 'string') {
        payload = {url: payload}
      }

      const {url, urls} = payload

      if (!url) {
        return
      }

      payload = {url: proxyUrl(url)}
      if (urls) {
        payload.urls = urls.map(url => proxyUrl(url))
      }

      console.log('preview', payload)

      if (window.isWechat) {
        return previewImage(payload.url, payload.urls)
      }

      this.previewComponent = this.previewComponent || new Vue({
        ...ImagePreviewer,
        parent: this.$root
      }).$mount()

      document.body.appendChild(this.previewComponent.$el)
      this.previewComponent.show(payload)
    }
  },
  beforeDestroy() {
    if (this.previewComponent) {
      this.previewComponent.$el.remove()
      this.previewComponent.$destroy()
    }
  }
}
